<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="192"
    height="192"
    fill="currentcolor"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <rect
      x="101.1"
      y="35.1"
      width="53.7"
      height="53.74"
      rx="8"
      transform="translate(-6.4 108.7) rotate(-45)"
      fill="none"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></rect>
    <rect
      x="167.1"
      y="101.1"
      width="53.7"
      height="53.74"
      rx="8"
      transform="translate(-33.7 174.7) rotate(-45)"
      fill="none"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></rect>
    <rect
      x="35.1"
      y="101.1"
      width="53.7"
      height="53.74"
      rx="8"
      transform="translate(-72.4 81.3) rotate(-45)"
      fill="none"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></rect>
    <rect
      x="101.1"
      y="167.1"
      width="53.7"
      height="53.74"
      rx="8"
      transform="translate(-99.7 147.3) rotate(-45)"
      fill="none"
      stroke="currentcolor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></rect>
  </svg>
</template>
