type Identity<T> = (t: T) => T
export type Pipe<T> = (t: T, next: Identity<T>) => T;
export function pipeline<T>(initialValue: T, pipes: Pipe<T>[], then: Identity<T> | null = null): T {
  then = then ?? ((t) => t);
  const pipelineCallback = pipes
    .slice()
    .reverse()
    .reduce<Identity<T>>((next, pipe) => (passable: T) => pipe(passable, next), then);

  return pipelineCallback(initialValue);
}

type AsyncIdentity<T> = (t: T) => Promise<T>
export type AsyncPipe<T> = (t: T, next: AsyncIdentity<T>) => Promise<T>;
export async function asyncPipeline<T>(initialValue: T, pipes: AsyncPipe<T>[], then: AsyncIdentity<T> | null = null): Promise<T> {
  then = then ?? (async (t) => t);
  const pipelineCallback = pipes
    .slice()
    .reverse()
    .reduce<AsyncIdentity<T>>((next, pipe) => (async (passable: T) => pipe(await passable, next)), then);

  return pipelineCallback(initialValue);
}
