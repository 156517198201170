import { TokenInfo } from '@/utils/tokenRegistry';
import { TokenMintAccountData } from '@/coders';
import { Account, AccountContext } from './AccountModel';
import { DecodedAccount } from './DecodedAccount';
import { TokenAmount } from './TokenAmount';

export class TokenMintAccount extends DecodedAccount {
  public readonly decodedData: TokenMintAccountData;

  public readonly tokenInfo: TokenInfo | null;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: TokenMintAccountData,
    tokenInfo: TokenInfo | null,
  ) {
    super(accountContext, owner, decodedData);
    this.decodedData = decodedData;
    this.tokenInfo = tokenInfo;
  }

  get maxSupply(): TokenAmount {
    return new TokenAmount(
      this.decodedData.supply,
      this.decodedData.decimals,
      this.tokenInfo?.symbol,
    );
  }
}
