<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="192"
    height="192"
    fill="currentColor"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <path
      d="M108,128a20,20,0,0,1,40,0,210.5,210.5,0,0,1-25,99.9"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M128,68a60,60,0,0,1,60,60c0,8.1-.4,16.1-1.1,24"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M51.3,192.2A130.4,130.4,0,0,0,68,128,59.7,59.7,0,0,1,88,83.3"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M103.3,168A168.7,168.7,0,0,1,83,217.3"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M179.8,192a233.4,233.4,0,0,1-8.4,26.1"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
    <path
      d="M22.3,160A91.7,91.7,0,0,0,28,128h0a100,100,0,0,1,200,0h0a293.3,293.3,0,0,1-7,64"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></path>
  </svg>
</template>
