import { isArray, isFunction } from 'lodash-es';

export const arrayWrap = <T>(value: T | T[]): T[] => (isArray(value) ? value : [value]);

export const unwrapFunction = (fn: unknown, ...args: unknown[]) => (isFunction(fn) ? fn(...args) : fn);

// eslint-disable-next-line no-control-regex
export const removeEmptyChars = (value: string) => value.replace(/\u0000/g, '');

export const padEmptyChars = (value: string, chars: number) => value.padEnd(chars, '\u0000');

export const zipMap = <T, U, V>(left: T[], right: U[], fn: (t: T, u: U | null, i: number) => V): V[] => (
  left.map((t: T, index) => fn(t, right?.[index] ?? null, index))
);

export const fetchJson = async <T>(uri: string): Promise<T | null> => {
  try {
    const response = await fetch(uri);
    const metadata: T = await response.json();
    return metadata;
  } catch (error) {
    return null;
  }
};

interface ListSummary {
  values: string[],
  hasMore: boolean,
  rest: number,
  total: number,
}

export const createListSummary = <T>(array: T[], fn: (t: T) => string, max = 3): ListSummary => {
  const total = array.length;
  const hasMore = total > max;
  const rest = hasMore ? (total - max) : 0;
  const values = array.slice(0, max).map(fn);

  return {
    values, hasMore, rest, total,
  };
};

export const toStorageSize = (storage: number) => {
  const unit = Math.floor(Math.log(storage) / Math.log(1024));
  const sizes = ['bytes', 'KB', 'MB', 'GB'];
  return `${(storage / 1024 ** unit).toFixed(2)}  ${sizes[unit]}`;
};
