import { NftAccount, NftMetadataAccount, TokenMintAccount } from '@/models';
import { AccountDetector } from '.';
import { fetchAccount } from '../fetchAccount';

export const nftAccountDetector: AccountDetector = async (account, next) => {
  if (!(account instanceof TokenMintAccount)) return next(account);

  const isNft = account.decodedData.supply.eqn(1)
    && account.decodedData.decimals === 0;
  if (!isNft) return next(account);

  const metadataAddress = await NftMetadataAccount.findAddress(account.publicKey);
  const metadataAccount = await fetchAccount(metadataAddress);
  if (!(metadataAccount instanceof NftMetadataAccount)) return next(account);

  return new NftAccount(
    account.context,
    account.owner,
    account.decodedData,
    account.tokenInfo,
    metadataAccount,
  );
};
