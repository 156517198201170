export class Cache<K, V> {
  public limit: number;

  public finder: (key: K, value: V) => boolean;

  public cache: V[];

  constructor(limit: number, finder: (key: K, value: V) => boolean) {
    this.limit = limit;
    this.finder = finder;
    this.cache = [];
  }

  add(key: K, value: V): void {
    if (!this.get(key)) {
      this.cache = [value, ...this.cache.slice(0, this.limit)];
    }
  }

  get(key: K): V | null {
    const record = this.cache.find((value: V) => this.finder(key, value));
    return record ?? null;
  }

  clear(): void {
    this.cache = [];
  }
}
