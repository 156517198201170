import { Layout } from './layouts';

export class Coder<T> {
  public readonly layout: Layout<T>;

  public readonly span: number;

  constructor(layout: Layout<T>) {
    this.layout = layout;
    this.span = layout.span;
  }

  decode(b: Uint8Array, offset?: number): T {
    return this.layout.decode(b, offset);
  }

  tryDecode(b: Uint8Array, offset?: number): T | null {
    if (b.length !== this.getSpan(b)) return null;
    return this.layout.decode(b, offset);
  }

  encode(src: T, b: Uint8Array, offset?: number): number {
    return this.layout.encode(src, b, offset);
  }

  getSpan(b: Uint8Array, offset?: number): number {
    return this.layout.getSpan(b, offset);
  }
}
