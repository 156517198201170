import { Layout, u8, UInt } from '@solana/buffer-layout';

export class OptionLayout<T> extends Layout<T | null> {
  layout: Layout<T>;

  discriminator: UInt;

  constructor(layout: Layout<T>, property?: string, discriminator?: UInt) {
    discriminator = discriminator ?? u8();
    super(layout.span + discriminator.span, property);
    this.layout = layout;
    this.discriminator = discriminator;
  }

  encode(src: T | null, b: Buffer, offset = 0): number {
    if (src === null || src === undefined) {
      return this.discriminator.encode(0, b, offset);
    }
    this.discriminator.encode(1, b, offset);
    return this.layout.encode(src, b, offset + this.discriminator.span) + this.discriminator.span;
  }

  decode(b: Buffer, offset = 0): T | null {
    const discriminator = this.discriminator.decode(b, offset);
    if (discriminator === 0) {
      return null;
    } if (discriminator === 1) {
      return this.layout.decode(b, offset + this.discriminator.span);
    }
    throw new Error(`Invalid option ${this.property}`);
  }

  getSpan(b: Buffer, offset = 0): number {
    return this.discriminator.span + this.layout.getSpan(b, offset + this.discriminator.span);
  }
}

export function option<T>(
  layout: Layout<T>,
  property?: string,
  discriminator?: UInt,
): Layout<T | null> {
  return new OptionLayout<T>(layout, property, discriminator);
}
