import { TokenInfo } from '@/utils/tokenRegistry';
import { TokenMintAccountData } from '@/coders';
import { Account, AccountContext } from './AccountModel';
import { TokenMintAccount } from './TokenMintAccount';
import { NftMetadataAccount, NftMetadata } from './NftMetadataAccount';

export class NftAccount extends TokenMintAccount {
  public readonly metadataAccount: NftMetadataAccount;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: TokenMintAccountData,
    tokenInfo: TokenInfo | null,
    metadataAccount: NftMetadataAccount,
  ) {
    super(accountContext, owner, decodedData, tokenInfo);
    this.metadataAccount = metadataAccount;
  }

  get metadata(): NftMetadata {
    return this.metadataAccount.metadata;
  }
}
