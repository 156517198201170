import { parseDecodedDataAsJson } from '@/utils';
import { Account, AccountContext } from './AccountModel';

export interface DecodedAccountData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key:string]: any
}

export class DecodedAccount extends Account {
  public readonly decodedData: DecodedAccountData;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: DecodedAccountData,
  ) {
    super(accountContext, owner);
    this.decodedData = decodedData;
  }

  get jsonData(): DecodedAccountData {
    return parseDecodedDataAsJson(this.decodedData);
  }
}
