import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';

export const useModal = createGlobalState(() => {
  const opened = ref(false);
  const component = ref('');
  const data = ref({});

  const open = (newComponent: string, newData: object = {}) => {
    opened.value = true;
    component.value = newComponent;
    data.value = newData;
    document.querySelector('body')?.classList.add('overflow-hidden');
  };

  const close = () => {
    opened.value = false;
    document.querySelector('body')?.classList.remove('overflow-hidden');
  };

  return {
    opened,
    component,
    data,
    open,
    close,
  };
});
