import { Coder, Idl } from '@project-serum/anchor';
import { AnchorAccount, CandyMachineConfigLine } from '@/models';
import { removeEmptyChars } from './common';

const MAX_NAME_LENGTH = 32;
const MAX_URI_LENGTH = 200;
const MAX_SYMBOL_LENGTH = 10;
const MAX_CREATOR_LEN = 32 + 1 + 1;
const MAX_CREATOR_LIMIT = 5;
const MAX_DATA_SIZE = 4 + MAX_NAME_LENGTH + 4 + MAX_SYMBOL_LENGTH + 4 + MAX_URI_LENGTH + 2 + 1 + 4 + MAX_CREATOR_LIMIT * MAX_CREATOR_LEN;
const MAX_METADATA_LEN = 1 + 32 + 32 + MAX_DATA_SIZE + 1 + 1 + 9 + 172;
const URI_START = 1 + 32 + 32 + 4 + MAX_NAME_LENGTH + 4 + MAX_SYMBOL_LENGTH + 4;
const CREATOR_ARRAY_START = 1 + 32 + 32 + 4 + MAX_NAME_LENGTH + 4 + MAX_SYMBOL_LENGTH + 4 + MAX_URI_LENGTH + 2 + 1 + 4;

const CONFIG_ARRAY_START = 32 // authority
  + 4
  + 6 // uuid + u32 len
  + 4
  + 10 // u32 len + symbol
  + 2 // seller fee basis points
  + 1
  + 4
  + 5 * 34 // optional + u32 len + actual vec
  + 8 // max supply
  + 1 // is mutable
  + 1 // retain authority
  + 4; // max number of lines;

const CONFIG_ARRAY_START_V2 = 8 // key
  + 32 // authority
  + 32 // wallet
  + 33 // token mint
  + 4
  + 6 // uuid
  + 8 // price
  + 8 // items available
  + 9 // go live
  + 10 // end settings
  + 4
  + MAX_SYMBOL_LENGTH // u32 len + symbol
  + 2 // seller fee basis points
  + 4
  + MAX_CREATOR_LIMIT * MAX_CREATOR_LEN // optional + u32 len + actual vec
  + 8 // max supply
  + 1 // is mutable
  + 1 // retain authority
  + 1 // option for hidden setting
  + 4
  + MAX_NAME_LENGTH // name length,
  + 4
  + MAX_URI_LENGTH // uri length,
  + 32 // hash
  + 4 // max number of lines;
  + 8 // items redeemed
  + 1 // whitelist option
  + 1 // whitelist mint mode
  + 1 // allow presale
  + 9 // discount price
  + 32 // mint key for whitelist
  + 1
  + 32
  + 1; // gatekeeper

export const metaplexConstants = {
  MAX_NAME_LENGTH,
  MAX_URI_LENGTH,
  MAX_SYMBOL_LENGTH,
  MAX_CREATOR_LEN,
  MAX_CREATOR_LIMIT,
  MAX_DATA_SIZE,
  MAX_METADATA_LEN,
  URI_START,
  CONFIG_ARRAY_START,
  CONFIG_ARRAY_START_V2,
  CREATOR_ARRAY_START,
};

export const getCandyMachineConfigLines = (account: AnchorAccount, configArrayStart: number): CandyMachineConfigLine[] => {
  const customIdl: Idl = {
    version: account.anchorIdl.version,
    name: account.anchorIdl.name,
    instructions: account.anchorIdl.instructions,
    types: account.anchorIdl.types,
    accounts: [{
      name: 'Config',
      type: {
        kind: 'struct',
        fields: [
          { name: 'configLines', type: { vec: { defined: 'ConfigLine' } } },
        ],
      },
    }],
  };

  // The coder will slice an extra 8 for the discriminator.
  const buffer = account.accountInfo.data.slice(configArrayStart - 8);
  const coder = new Coder(customIdl);
  const configLines: CandyMachineConfigLine[] = coder.accounts.decode('Config', buffer)?.configLines ?? [];

  configLines.forEach((configLine: CandyMachineConfigLine) => {
    configLine.name = removeEmptyChars(configLine.name);
    configLine.uri = removeEmptyChars(configLine.uri);
  });

  return configLines;
};
