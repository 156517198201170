import { PublicKey } from '@solana/web3.js';
import { Loader } from './Loader';
import { CandyMachineConfigAccount } from '@/models';

export class CandyMachineFromConfigLoader extends Loader {
  public readonly account: CandyMachineConfigAccount;

  public publicKey: PublicKey | null = null;

  constructor(account: CandyMachineConfigAccount) {
    super(account);
    this.account = account;
  }

  async handle() {
    const matchingCandyMachines = await this.account.anchorProgram.account.candyMachine.all([{
      memcmp: {
        offset: 8 + 32 + 33,
        bytes: this.account.publicKey.toBase58(),
      },
    }]);

    this.publicKey = matchingCandyMachines?.[0]?.publicKey ?? null;
  }
}
