import { AccountLayout } from '@solana/spl-token';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
import { chunk } from 'lodash-es';
import { Loader } from './Loader';
import {
  accountPublicKeys, metaplexConstants, removeEmptyChars, zipMap,
} from '@/utils';
import { useWorkspace } from '@/composables';
import { NftMetadataAccount } from '@/models';

interface TokenInfo {
  token: PublicKey,
  mint: PublicKey,
  metadata: PublicKey | null,
  metadataUri: string | null,
  amount: BN,
  isNft: boolean,
}

const getMetadataUri = (metadataAccount: AccountInfo<Buffer>): string | null => {
  const uriSlice = metadataAccount.data.slice(
    metaplexConstants.URI_START,
    metaplexConstants.URI_START + metaplexConstants.MAX_URI_LENGTH,
  );
  const parsedUri = removeEmptyChars(uriSlice.toString());
  return parsedUri || null;
};

export class TokenLoader extends Loader {
  public all: TokenInfo[] = [];

  async handle() {
    const { connection } = useWorkspace();
    const tokens = await connection.value.getProgramAccounts(accountPublicKeys.tokenProgram, {
      dataSlice: { offset: 0, length: 32 + 32 + 8 }, // Mint + Owner + Amount.
      filters: [
        { dataSize: AccountLayout.span }, // Token Account.
        { memcmp: { offset: 32, bytes: this.account.publicKey.toBase58() } }, // From owner.
      ],
    });

    const promises = tokens.map(async (token) => {
      const mint = new PublicKey(token.account.data.slice(0, 32));
      const amount = new BN(token.account.data.slice(64), 'le');
      const metadata = await NftMetadataAccount.findAddress(mint);
      return {
        token: token.pubkey, mint, metadata, amount,
      };
    });

    const tokenInfos = await Promise.all(promises);

    const metadataChunkPromises = chunk(tokenInfos.map((x) => x.metadata), 100).map(async (metadataChunk) => {
      try {
        // TODO: Add dataSlice(0,0) when next release of web3.js is out.
        return await connection.value.getMultipleAccountsInfo(metadataChunk);
      } catch (error) {
        return metadataChunk.map(() => null);
      }
    });

    const metadataAccounts = (await Promise.allSettled(metadataChunkPromises))
      .flatMap((result) => (result.status === 'fulfilled' ? result.value : []));

    this.all = zipMap(tokenInfos, metadataAccounts, (tokenInfo, metadataAccount) => {
      const metadataUri = metadataAccount ? getMetadataUri(metadataAccount) : null;
      const isNft = !!(metadataAccount && metadataUri);

      return {
        ...tokenInfo,
        metadata: isNft ? tokenInfo.metadata : null,
        metadataUri,
        isNft,
      };
    });
  }
}
