import { Account, AccountContext } from './AccountModel';
import { DecodedAccount, DecodedAccountData } from './DecodedAccount';

export class SHDWStorageAccount extends DecodedAccount {
  public readonly totalFiles;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: DecodedAccountData,
    totalFiles: number,
  ) {
    super(accountContext, owner, decodedData);
    this.totalFiles = totalFiles;
  }
}
