import { ref, watch } from 'vue';
import { createGlobalState } from '@vueuse/core';
import {
  TokenListProvider, TokenInfo, TokenListContainer, ENV,
} from '@/utils/tokenRegistry';
import { AccountAddress, toPublicKey } from '@/utils';
import { ClusterName, useWorkspace } from './useWorkspace';

export const useTokenInfoRepository = createGlobalState(() => {
  const { clusterName } = useWorkspace();
  const tokenListContainer = ref<TokenListContainer | null>(null);

  const getChainId = (cluster: ClusterName): number | ENV => {
    if (cluster === 'Mainnet') return ENV.MainnetBeta;
    if (cluster === 'Testnet') return ENV.Testnet;
    if (cluster === 'Devnet') return ENV.Devnet;
    return 0;
  };

  const getTokenList = async (): Promise<TokenInfo[]> => {
    if (!tokenListContainer.value) {
      const newContainer = await new TokenListProvider().resolve();
      tokenListContainer.value = newContainer.filterByChainId(getChainId(clusterName.value));
    }
    return tokenListContainer.value.getList();
  };

  watch(clusterName, () => {
    tokenListContainer.value = null;
  });

  const getTokenInfo = async (address: AccountAddress): Promise<TokenInfo | null> => {
    const publicKey = toPublicKey(address);
    const list = await getTokenList();
    return list.find((t) => t.address === publicKey.toBase58()) ?? null;
  };

  return {
    getTokenInfo,
  };
});
