import { Program } from '@project-serum/anchor';
import { Account, AccountContext } from './AccountModel';
import { DecodedAccountData } from './DecodedAccount';
import { AnchorAccount } from './AnchorAccount';
import { NftMetadata } from './NftMetadataAccount';
import { CandyMachineFromConfigLoader } from '@/loaders';

export interface CandyMachineConfigLine {
  name: string,
  uri: string,
}

export class CandyMachineConfigAccount extends AnchorAccount {
  public readonly configLines: CandyMachineConfigLine[];

  public readonly configMetadata: NftMetadata | null;

  public readonly candyMachine: CandyMachineFromConfigLoader;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: DecodedAccountData,
    anchorProgram: Program,
    anchorAccountName: string,
    configLines: CandyMachineConfigLine[],
    configMetadata: NftMetadata | null = null,
  ) {
    super(accountContext, owner, decodedData, anchorProgram, anchorAccountName);
    this.configLines = configLines;
    this.configMetadata = configMetadata;
    this.candyMachine = new CandyMachineFromConfigLoader(this);
  }
}
