import { TokenMintAccount } from '@/models';
import { AccountDetector } from '.';
import { TokenMintAccountCoder } from '@/coders';
import { useTokenInfoRepository } from '@/composables';

export const tokenMintAccountDetector: AccountDetector = async (account, next) => {
  if (!account.owner?.isRecognized('tokenProgram')) return next(account);
  const decodedData = TokenMintAccountCoder.tryDecode(account.accountInfo.data);
  if (!decodedData) return next(account);

  const { getTokenInfo } = useTokenInfoRepository();
  const tokenInfo = await getTokenInfo(account.publicKey);

  return next(new TokenMintAccount(account.context, account.owner, decodedData, tokenInfo));
};
