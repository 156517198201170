import { Layout } from '@solana/buffer-layout';
import { vecU8 } from './VectorLayouts';
import { WrappedLayout } from './WrappedLayout';

export function str(property?: string): Layout<string> {
  return new WrappedLayout(
    vecU8(),
    (data) => Buffer.from(data).toString('utf-8'),
    (s) => new Uint8Array(Buffer.from(s, 'utf-8')),
    property,
  );
}
