import { Idl, Program } from '@project-serum/anchor';
import { Account, AccountContext } from './AccountModel';
import { DecodedAccount, DecodedAccountData } from './DecodedAccount';

export class AnchorAccount extends DecodedAccount {
  public readonly anchorProgram: Program;

  public readonly anchorIdl: Idl;

  public readonly anchorAccountName: string;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: DecodedAccountData,
    anchorProgram: Program,
    anchorAccountName: string,
  ) {
    super(accountContext, owner, decodedData);
    this.anchorProgram = anchorProgram;
    this.anchorIdl = anchorProgram.idl;
    this.anchorAccountName = anchorAccountName;
  }
}
