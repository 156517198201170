import bs58 from 'bs58';
import BN from 'bn.js';
import { PublicKey } from '@solana/web3.js';
import { AccountLayout } from '@solana/spl-token';
import { useWorkspace } from '@/composables';
import { Loader } from './Loader';
import { accountPublicKeys } from '@/utils';
import { NftMetadataAccount } from '@/models';

export class NftOwnerLoader extends Loader {
  public readonly account: NftMetadataAccount;

  public owner: PublicKey | null = null;

  public token: PublicKey | null = null;

  constructor(account: NftMetadataAccount) {
    super(account);
    this.account = account;
  }

  async handle() {
    const { connection } = useWorkspace();
    const nftOwners = await connection.value.getProgramAccounts(
      accountPublicKeys.tokenProgram,
      {
        dataSlice: { offset: 32, length: 32 }, // Get the Token Owner.
        filters: [
          { dataSize: AccountLayout.span }, // Token Account Size.
          { memcmp: { offset: 0, bytes: this.account.decodedData.mint.toBase58() } }, // Mint.
          { memcmp: { offset: 64, bytes: bs58.encode((new BN(1, 'le')).toArray()) } }, // Amount = 1 unit.
        ],
      },
    );

    if (nftOwners.length <= 0) {
      return;
    }

    this.owner = new PublicKey(nftOwners[0].account.data);
    this.token = nftOwners[0].pubkey;
  }
}
