import { onBeforeRouteUpdate, RouteLocationNormalizedLoaded, useRoute } from 'vue-router';

type UseFromRouteCallback = (to: RouteLocationNormalizedLoaded, from: RouteLocationNormalizedLoaded | null) => unknown;

export const useFromRoute = (fn: UseFromRouteCallback) => {
  fn(useRoute(), null);
  onBeforeRouteUpdate(async (to, from, next) => {
    await fn(to, from);
    next();
  });
};
