import { AccountDetector } from '.';
import { NftMetadataCoder } from '@/coders';
import { fetchJson } from '@/utils';
import { NftMetadata, NftMetadataAccount } from '@/models';

export const nftMetadataAccountDetector: AccountDetector = async (account, next) => {
  const isMetadataAccount = !account.isOnCurve()
    && (account.owner?.isRecognized('tokenMetadataProgram') ?? false);
  if (!isMetadataAccount) return next(account);

  const decodedData = NftMetadataCoder.decode(account.accountInfo.data);
  if (decodedData.key !== 4) return next(account);

  const metadata = await fetchJson<NftMetadata>(decodedData.data.uri);
  if (!metadata) return next(account);

  return new NftMetadataAccount(
    account.context,
    account.owner,
    decodedData,
    metadata,
  );
};
