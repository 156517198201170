import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
import {
  struct, publicKey, u8, u32, bnu64, WrappedLayout,
} from './layouts';
import { Coder } from './Coder';

export type RawTokenAccountData = {
  mint: PublicKey;
  owner: PublicKey;
  amount: BN;
  delegateOption: number;
  delegate: PublicKey | null;
  state: number;
  isNativeOption: number;
  isNative: BN;
  delegatedAmount: BN,
  closeAuthorityOption: number,
  closeAuthority: PublicKey | null,
};

const rawLayout = struct<RawTokenAccountData>([
  publicKey('mint'),
  publicKey('owner'),
  bnu64('amount'),
  u32('delegateOption'),
  publicKey('delegate'),
  u8('state'),
  u32('isNativeOption'),
  bnu64('isNative'),
  bnu64('delegatedAmount'),
  u32('closeAuthorityOption'),
  publicKey('closeAuthority'),
]);

export type TokenAccountData = {
  mint: PublicKey;
  owner: PublicKey;
  amount: BN;
  delegate: null | PublicKey;
  delegatedAmount: BN;
  state: number;
  isInitialized: boolean;
  isFrozen: boolean;
  isNative: boolean;
  rentExemptReserve: null | BN;
  closeAuthority: null | PublicKey;
};

const layout = new WrappedLayout(
  rawLayout,
  (raw: RawTokenAccountData): TokenAccountData => ({
    mint: raw.mint,
    owner: raw.owner,
    amount: raw.amount,
    delegate: raw.delegateOption !== 0 ? new PublicKey(raw.delegate ?? 0) : null,
    delegatedAmount: new BN(raw.delegateOption !== 0 ? raw.delegatedAmount : 0),
    state: raw.state,
    isInitialized: raw.state !== 0,
    isFrozen: raw.state === 2,
    isNative: raw.isNativeOption === 1,
    rentExemptReserve: raw.isNativeOption === 1 ? raw.isNative : null,
    closeAuthority: raw.closeAuthorityOption !== 0 ? new PublicKey(raw.closeAuthority ?? 0) : null,
  }),
  (data: TokenAccountData): RawTokenAccountData => ({
    mint: data.mint,
    owner: data.owner,
    amount: data.amount,
    delegateOption: data.delegate ? 1 : 0,
    delegate: data.delegate,
    state: data.state,
    isNativeOption: data.isNative ? 1 : 0,
    isNative: data.rentExemptReserve ?? new BN(0),
    delegatedAmount: data.delegatedAmount,
    closeAuthorityOption: data.closeAuthority ? 1 : 0,
    closeAuthority: data.closeAuthority,
  }),
);

export const TokenAccountCoder = new Coder<TokenAccountData>(layout);
