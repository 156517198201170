import { Layout, blob } from '@solana/buffer-layout';
import { PublicKey } from '@solana/web3.js';
import { WrappedLayout } from './WrappedLayout';

export const publicKey = (property?: string): Layout<PublicKey> => new WrappedLayout(
  blob(32),
  (b: Uint8Array) => new PublicKey(b),
  (key: PublicKey) => new Uint8Array(key.toBuffer()),
  property,
);
