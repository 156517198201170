import { TokenInfo } from '@/utils/tokenRegistry';
import { TokenAccountData } from '@/coders';
import { Account, AccountContext } from './AccountModel';
import { DecodedAccount } from './DecodedAccount';
import { TokenMintAccount } from './TokenMintAccount';
import { TokenAmount } from './TokenAmount';

export class TokenAccount extends DecodedAccount {
  public readonly decodedData: TokenAccountData;

  public readonly tokenMintAccount: TokenMintAccount;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: TokenAccountData,
    tokenMintAccount: TokenMintAccount,
  ) {
    super(accountContext, owner, decodedData);
    this.decodedData = decodedData;
    this.tokenMintAccount = tokenMintAccount;
  }

  get amount(): TokenAmount {
    return new TokenAmount(
      this.decodedData.amount,
      this.tokenMintAccount.decodedData.decimals,
      this.tokenInfo?.symbol,
    );
  }

  get tokenInfo() : TokenInfo | null {
    return this.tokenMintAccount.tokenInfo;
  }
}
