import { Program } from '@project-serum/anchor';
import { useWorkspace } from '@/composables';
import { AnchorProgram } from '@/models';
import { AccountDetector } from '.';
import { accountPublicKeys } from '@/utils';

const recognizedNonAnchorPrograms = [
  'systemProgram',
  'bpfLoaderUpgradableProgram',
  'nativeLoaderProgram',
  'tokenProgram',
  'associatedTokenAccountProgram',
  'tokenMetadataProgram',
];

export const anchorProgramDetector: AccountDetector = async (account, next) => {
  if (!account.isProgram) return next(account);

  const isRecognizedNonAnchorProgram = recognizedNonAnchorPrograms.some((key) => account.hasAddress(accountPublicKeys[key]));
  if (isRecognizedNonAnchorProgram) return next(account);

  const { provider } = useWorkspace();
  console.log('Fetching IDL for...', account.publicKey.toBase58());
  const anchorIdl = await Program.fetchIdl(account.publicKey, provider.value);
  if (!anchorIdl) return next(account);

  const anchorProgram = new Program(anchorIdl, account.publicKey, provider.value);
  return next(new AnchorProgram(account.context, account.owner, anchorProgram));
};
