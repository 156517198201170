/* eslint-disable camelcase */
import { PublicKey } from '@solana/web3.js';
import { NftMetadataAccountData } from '@/coders';
import { AccountAddress, accountPublicKeys, toPublicKey } from '@/utils';
import { Account, AccountContext } from './AccountModel';
import { DecodedAccount } from './DecodedAccount';
import { NftOwnerLoader } from '@/loaders';

export interface NftMetadataAttribute {
  trait_type?: string,
  value?: unknown,
  [key: string]: unknown,
}

export interface NftMetadataCreator {
  address?: string,
  share?: number,
  [key: string]: unknown,
}

export interface NftMetadata {
  name?: string,
  symbol?: string,
  description?: string,
  seller_fee_basis_points?: number,
  image?: string,
  external_url?: string,
  attributes?: NftMetadataAttribute[],
  properties?:{
    creators?: NftMetadataCreator[],
    files?: {
      type?: string,
      uri?: string,
    }[],
  },
  collection?: {
    name?: string,
    family?: string,
  }
  [key: string]: unknown,
}

export class NftMetadataAccount extends DecodedAccount {
  public readonly decodedData: NftMetadataAccountData;

  public readonly metadata: NftMetadata;

  public readonly nftOwner: NftOwnerLoader;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: NftMetadataAccountData,
    metadata: NftMetadata,
  ) {
    super(accountContext, owner, decodedData);
    this.decodedData = decodedData;
    this.metadata = metadata;
    this.nftOwner = new NftOwnerLoader(this);
  }

  static async findAddressWithBump(mintPublicKey: AccountAddress): Promise<[PublicKey, number]> {
    return PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        accountPublicKeys.tokenMetadataProgram.toBuffer(),
        toPublicKey(mintPublicKey).toBuffer(),
      ],
      accountPublicKeys.tokenMetadataProgram,
    );
  }

  static async findAddress(mintPublicKey: AccountAddress): Promise<PublicKey> {
    return (await this.findAddressWithBump(mintPublicKey))[0];
  }
}
