import { Program } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { Account, AccountContext } from './AccountModel';
import { DecodedAccountData } from './DecodedAccount';
import { AnchorAccount } from './AnchorAccount';
import { CandyMachineConfigLine } from './CandyMachineConfigAccount';
import { TokenAmount } from './TokenAmount';
import { NftMetadata } from './NftMetadataAccount';
import { accountPublicKeys } from '@/utils';

export class CandyMachineV2Account extends AnchorAccount {
  public readonly configLines: CandyMachineConfigLine[];

  public readonly configMetadata: NftMetadata | null;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: DecodedAccountData,
    anchorProgram: Program,
    anchorAccountName: string,
    configLines: CandyMachineConfigLine[],
    configMetadata: NftMetadata | null = null,
  ) {
    super(accountContext, owner, decodedData, anchorProgram, anchorAccountName);
    this.configLines = configLines;
    this.configMetadata = configMetadata;
  }

  get mintPrice(): TokenAmount {
    return new TokenAmount(this.decodedData.data.price, 9, 'SOL');
  }

  async findCreatorAddressWithBump(): Promise<[PublicKey, number]> {
    return PublicKey.findProgramAddress(
      [Buffer.from('candy_machine'), this.publicKey.toBuffer()],
      accountPublicKeys.candyMachineV2Program,
    );
  }

  async findCreatorAddress(): Promise<PublicKey> {
    return (await this.findCreatorAddressWithBump())[0];
  }
}
