import { Coder } from '@project-serum/anchor';
import { AnchorAccount, AnchorProgram } from '@/models';
import { identifyAnchorAccount } from '@/utils';
import { AccountDetector } from '.';

export const anchorAccountDetector: AccountDetector = async (account, next) => {
  if (account.isProgram) return next(account);
  if (!(account.owner instanceof AnchorProgram)) return next(account);

  const { anchorProgram, anchorIdl } = account.owner;
  const anchorAccountName = identifyAnchorAccount(account.accountInfo, anchorIdl);
  if (!anchorAccountName) return next(account);

  const coder = new Coder(anchorIdl);
  const decodedData = coder.accounts.decode(anchorAccountName, account.accountInfo.data);

  return next(new AnchorAccount(
    account.context,
    account.owner,
    decodedData,
    anchorProgram,
    anchorAccountName,
  ));
};
