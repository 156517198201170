import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
import {
  struct, publicKey, u8, u32, bnu64, bool, option,
} from './layouts';
import { Coder } from './Coder';

export type TokenMintAccountData = {
  mintAuthority: null | PublicKey;
  supply: BN;
  decimals: number;
  isInitialized: boolean;
  freezeAuthority: null | PublicKey;
};

const layout = struct<TokenMintAccountData>([
  option(publicKey('mintAuthority'), 'mintAuthority', u32()),
  bnu64('supply'),
  u8('decimals'),
  bool('isInitialized'),
  option(publicKey('freezeAuthority'), 'freezeAuthority', u32()),
]);

export const TokenMintAccountCoder = new Coder<TokenMintAccountData>(layout);
