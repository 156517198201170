import {
  Layout, struct, u32, seq, offset, blob,
} from '@solana/buffer-layout';
import { WrappedLayout } from './WrappedLayout';

export function vec<T>(
  elementLayout: Layout<T>,
  property?: string,
): Layout<T[]> {
  const length = u32('length');
  const layout: Layout<{ length: number, values: T[] }> = struct([
    length,
    seq(elementLayout, offset(length, -length.span), 'values'),
  ]);
  return new WrappedLayout(
    layout,
    ({ values }) => values,
    (values) => ({ values, length: values.length }),
    property,
  );
}

export function vecU8(property?: string): Layout<Uint8Array> {
  const length = u32('length');
  const layout: Layout<{ length: number, data: Uint8Array }> = struct([
    length,
    blob(offset(length, -length.span), 'data'),
  ]);
  return new WrappedLayout(
    layout,
    ({ data }) => data,
    (data) => ({ data, length: data.length }),
    property,
  );
}
