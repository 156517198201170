import { AccountInfo, PublicKey } from '@solana/web3.js';
import { TokenAmount } from './TokenAmount';
import {
  AccountAddress,
  accountDictionary,
  parseDecodedDataAsJson,
  RecognizedAccount,
  toPublicKey,
} from '@/utils';
import { RelatedAccountLoader, TokenLoader } from '@/loaders';

export interface AccountContext {
  publicKey: PublicKey,
  accountInfo: AccountInfo<Buffer>,
}

export class Account {
  public readonly publicKey: PublicKey

  public readonly accountInfo: AccountInfo<Buffer>

  public readonly owner: Account | null = null;

  public readonly recognizedAccount: RecognizedAccount | null;

  public readonly relatedAccounts: RelatedAccountLoader;

  public readonly tokens: TokenLoader;

  constructor({ publicKey, accountInfo }: AccountContext, owner: Account | null) {
    this.publicKey = publicKey;
    this.accountInfo = accountInfo;
    this.owner = owner;
    this.recognizedAccount = accountDictionary[publicKey.toBase58()];
    this.relatedAccounts = new RelatedAccountLoader(this);
    this.tokens = new TokenLoader(this);
  }

  get context(): AccountContext {
    return { publicKey: this.publicKey, accountInfo: this.accountInfo };
  }

  get isProgram(): boolean {
    return this.accountInfo.executable;
  }

  get lamports(): TokenAmount {
    return new TokenAmount(this.accountInfo.lamports, 9, 'SOL');
  }

  get parsedAccountInfo(): object {
    return parseDecodedDataAsJson(this.accountInfo);
  }

  hasAddress(address: AccountAddress): boolean {
    try {
      return this.publicKey.toBase58() === toPublicKey(address).toBase58();
    } catch (error) {
      return false;
    }
  }

  isRecognized(key: string) {
    return this.recognizedAccount?.key === key;
  }

  isOnCurve() {
    return PublicKey.isOnCurve(this.publicKey.toBytes());
  }
}
