import { ref, watch } from 'vue';
import dayjs, { Dayjs } from 'dayjs';

export const useCountdown = (goLiveDate: Dayjs | null) => {
  const distance = ref(0);
  const countdown = ref('');
  const isLive = ref(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let invalidateCountdown = () => {};

  if (goLiveDate) {
    const countdownHandler = () => {
      const currentDate = dayjs().unix();
      const newDistance = goLiveDate.unix() - currentDate;
      const days = Math.floor(newDistance / (60 * 60 * 24));
      const hours = Math.floor((newDistance % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((newDistance % (60 * 60)) / (60));
      const seconds = Math.floor((newDistance % (60)) / 1000);

      distance.value = newDistance;
      countdown.value = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      if (newDistance < 0) isLive.value = true;
    };
    const countdownInterval = setInterval(countdownHandler, 1000);
    countdownHandler();

    invalidateCountdown = () => {
      if (!countdownInterval) return;
      clearInterval(countdownInterval);
    };

    watch(distance, (newDistance) => {
      if (newDistance < 0) {
        invalidateCountdown();
      }
    });
  }

  return {
    distance,
    countdown,
    isLive,
    invalidateCountdown,
  };
};
