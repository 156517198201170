import BN from 'bn.js';

export class TokenAmount {
  public readonly amount: BN;

  public readonly decimals: number;

  public readonly symbol: string | null;

  public readonly units: BN;

  public readonly fractions: BN;

  public readonly number: number;

  constructor(amount: BN | number, decimals: number, symbol: string | null = null) {
    this.amount = new BN(amount);
    this.decimals = decimals;
    this.symbol = symbol;
    this.units = this.amount.div(new BN(10 ** this.decimals));
    this.fractions = this.amount.mod(new BN(10 ** this.decimals));
    const paddedFractions = `${this.fractions}`.padStart(this.decimals, '0');
    this.number = parseFloat(`${this.units}.${paddedFractions}`);
  }

  get formatted(): string {
    return this.number.toLocaleString();
  }

  get formattedWithSymbol(): string {
    return `${this.formatted}${this.symbol ? ` ${this.symbol}` : ''}`;
  }
}
