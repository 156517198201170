import { flatMap, isArray, isPlainObject } from 'lodash-es';
import { PublicKey } from '@solana/web3.js';
import {
  CandyMachineAccount,
  CandyMachineConfigAccount,
  CandyMachineV2Account,
  DecodedAccount,
  NftAccount,
  NftHolderAccount,
  NftMetadataAccount,
  RelatedAccount,
} from '@/models';
import { Loader } from './Loader';

export class RelatedAccountLoader extends Loader {
  public all: RelatedAccount[] = [];

  async handle() {
    // Generic.
    this.all.push(this.getOwnerRelatedAccount());
    this.all.push(...this.getRelatedAccountsFromDecodedData());

    // Specific.
    if (this.account instanceof CandyMachineAccount) await this.handleCandyMachineAccount();
    else if (this.account instanceof CandyMachineConfigAccount) await this.handleCandyMachineConfigAccount();
    else if (this.account instanceof CandyMachineV2Account) await this.handleCandyMachineV2Account();
    else if (this.account instanceof NftAccount || this.account instanceof NftHolderAccount) await this.handleNftAccount();
    else if (this.account instanceof NftMetadataAccount) await this.handleNftMetadataAccount();
  }

  getOwnerRelatedAccount(): RelatedAccount {
    return new RelatedAccount(
      this.account.accountInfo.owner,
      'owner-program',
      'Owner Program',
      'The program that owns this account.',
    );
  }

  getRelatedAccountsFromDecodedData(): RelatedAccount[] {
    const recursiveFlatMap = (data: object, parentKey = ''): RelatedAccount[] => flatMap(data, (value: unknown, key: string) => {
      key = parentKey ? (`${parentKey}.${key}`) : key;
      if (isPlainObject(value) || isArray(value)) return recursiveFlatMap(value as object, key);
      if (!(value instanceof PublicKey)) return [];
      return [new RelatedAccount(value, key)];
    });

    return this.account instanceof DecodedAccount
      ? recursiveFlatMap(this.account.decodedData)
      : [];
  }

  async handleCandyMachineAccount() {
    if (!(this.account instanceof CandyMachineAccount)) return;
    if (!this.account.configAccount) return;

    const configRelatedAccounts = this.account.configAccount.relatedAccounts;
    await configRelatedAccounts.load();

    this.all.push(...configRelatedAccounts.all.filter((relatedAccount: RelatedAccount) => (
      relatedAccount.relationshipKey.startsWith('data.creators.')
    )));
  }

  async handleCandyMachineConfigAccount() {
    if (!(this.account instanceof CandyMachineConfigAccount)) return;

    this.all = this.all.map((relatedAccount: RelatedAccount) => {
      const matches = relatedAccount.relationshipKey.match(/^data.creators\.(\d+)\.address$/);
      if (!matches) return relatedAccount;

      const index: number = parseInt(matches[1], 10);
      const { share } = (this.account as CandyMachineConfigAccount).decodedData.data.creators[index];
      return relatedAccount.rename(`Creator ${index + 1} (${share}%)`);
    });

    const candyMachineLoader = this.account.candyMachine;
    await candyMachineLoader.load();

    if (candyMachineLoader.publicKey) {
      this.all.push(new RelatedAccount(candyMachineLoader.publicKey, 'candyMachine', 'Candy Machine'));
    }
  }

  async handleCandyMachineV2Account() {
    if (!(this.account instanceof CandyMachineV2Account)) return;

    this.all = this.all.map((relatedAccount: RelatedAccount) => {
      let matches = relatedAccount.relationshipKey.match(/^data.creators\.(\d+)\.address$/);
      if (matches) {
        const index: number = parseInt(matches[1], 10);
        const { share } = (this.account as CandyMachineV2Account).decodedData.data.creators[index];
        return relatedAccount.rename(`Creator ${index + 1} (${share}%)`);
      }

      matches = relatedAccount.relationshipKey.match(/^data.whitelistMintSettings.mint$/);
      if (matches) return relatedAccount.rename('Whitelist Mint');

      matches = relatedAccount.relationshipKey.match(/^data.gatekeeper.gatekeeperNetwork$/);
      if (matches) return relatedAccount.rename('Gatekeeper Network');

      return relatedAccount;
    });
  }

  async handleNftAccount() {
    if (!(this.account instanceof NftAccount || this.account instanceof NftHolderAccount)) return;

    const metadataRelatedAccounts = this.account.metadataAccount.relatedAccounts;
    await metadataRelatedAccounts.load();

    this.all.push(...[
      new RelatedAccount(this.account.metadataAccount.publicKey, 'nft-metadata', 'Metadata'),
      ...metadataRelatedAccounts.all.filter((relatedAccount: RelatedAccount) => (
        relatedAccount.relationshipKey === 'updateAuthority'
        || relatedAccount.relationshipKey.startsWith('data.creators.')
        || relatedAccount.relationshipKey.startsWith('nft.')
      )),
    ]);
  }

  async handleNftMetadataAccount() {
    if (!(this.account instanceof NftMetadataAccount)) return;

    this.all = this.all.map((relatedAccount: RelatedAccount) => {
      const matches = relatedAccount.relationshipKey.match(/^data.creators\.(\d+)\.address$/);
      if (!matches) return relatedAccount;

      const index: number = parseInt(matches[1], 10);
      const creator = (this.account as NftMetadataAccount).decodedData.data.creators?.[index];
      if (!creator) return relatedAccount;

      return relatedAccount.rename(`Creator ${index + 1} (${creator.share}%)`);
    });

    const nftOwnerLoader = this.account.nftOwner;
    await nftOwnerLoader.load();

    if (nftOwnerLoader.owner) {
      this.all.push(new RelatedAccount(nftOwnerLoader.owner, 'nft.owner', 'NFT Owner'));
    }

    if (nftOwnerLoader.token) {
      this.all.push(new RelatedAccount(nftOwnerLoader.token, 'nft.token', 'Token'));
    }
  }
}
