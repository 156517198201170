import { startCase } from 'lodash-es';
import {
  Account,
  AnchorAccount,
  AnchorProgram,
  CandyMachineAccount,
  CandyMachineConfigAccount,
  CandyMachineV2Account,
  DecodedAccount,
  NftAccount,
  NftHolderAccount,
  NftMetadataAccount,
  SHDWFileAccount,
  SHDWStorageAccount,
  TokenAccount,
  TokenMintAccount,
  WalletAccount,
} from '.';

interface AccountTab {
  key: string,
  label: string,
  component: string,
  props?: object,
  when?: boolean | (() => boolean),
}

export class AccountPresenter {
  public readonly account: Account;

  public readonly name: string;

  public readonly icon: string;

  public readonly iconClass: string;

  public readonly iconUrl: string | null;

  public readonly tabs: AccountTab[];

  public readonly overview: string | null;

  constructor(account: Account) {
    this.account = account;
    this.name = AccountPresenter.parseName(account);
    this.icon = AccountPresenter.parseIcon(account);
    this.iconClass = AccountPresenter.parseIconClass(account);
    this.iconUrl = AccountPresenter.parseIconUrl(account);
    this.tabs = AccountPresenter.parseTabs(account);
    this.overview = AccountPresenter.parseOverview(account);
  }

  static parseName(account: Account): string {
    if (account.recognizedAccount) return account.recognizedAccount.name;
    if (account instanceof WalletAccount) return 'Wallet Account';
    if (account instanceof NftAccount) return account.metadata.name ? `${account.metadata.name} (Mint)` : 'NFT Mint Account';
    if (account instanceof NftHolderAccount) return account.metadata.name ? `${account.metadata.name} (Token)` : 'NFT Token Account';
    if (account instanceof NftMetadataAccount) return account.metadata.name ? `${account.metadata.name} (Metadata)` : 'NFT Metadata Account';
    if (account instanceof TokenAccount) return account.tokenInfo?.name ? `${account.tokenInfo?.name} Token Account` : 'Token Account';
    if (account instanceof TokenMintAccount) return account.tokenInfo?.name ? `${account.tokenInfo?.name} Mint Account` : 'Mint Account';
    if (account instanceof CandyMachineV2Account) return account.configMetadata?.collection?.name ?? 'Metaplex Candy Machine V2';
    if (account instanceof CandyMachineAccount) return account.configAccount?.configMetadata?.collection?.name ?? 'Metaplex Candy Machine';
    if (account instanceof CandyMachineConfigAccount) return account.configMetadata?.collection?.name ? `${account.configMetadata.collection.name} (Config)` : 'Metaplex Candy Machine (Config)';
    if (account instanceof SHDWFileAccount) return 'ShadowDrive File';
    if (account instanceof SHDWStorageAccount) return `${account.decodedData.identifier} (ShadowDrive Storage)`;
    if (account instanceof AnchorProgram) return startCase(account.anchorIdl.name);
    if (account instanceof AnchorAccount) return startCase(account.anchorAccountName);
    if (account.isProgram) return 'Program';
    return 'Account';
  }

  static parseIcon(account: Account): string {
    if (account.recognizedAccount?.icon) return account.recognizedAccount.icon;
    if (account instanceof WalletAccount) return 'icon-wallet';
    if (account instanceof NftAccount || account instanceof NftHolderAccount || account instanceof NftMetadataAccount) return 'icon-image-square';
    if (account instanceof TokenAccount) return 'icon-coins';
    if (account instanceof TokenMintAccount) return 'icon-vault';
    if (account instanceof CandyMachineV2Account || account instanceof CandyMachineAccount || account instanceof CandyMachineConfigAccount) return 'icon-cookie';
    if (account instanceof AnchorAccount || account instanceof AnchorProgram) return 'icon-anchor';
    if (account.isProgram) return 'icon-cpu';
    return 'icon-archive';
  }

  static parseIconClass(account: Account): string {
    if (account.recognizedAccount?.iconClass) return account.recognizedAccount.iconClass;
    if (account instanceof NftAccount || account instanceof NftHolderAccount || account instanceof NftMetadataAccount) return 'bg-gradient-to-br from-cyan-400 to-blue-500 text-white';
    if (account instanceof CandyMachineV2Account || account instanceof CandyMachineAccount || account instanceof CandyMachineConfigAccount) return 'bg-gradient-to-br from-yellow-400 to-orange-500 text-white';
    if (account.isProgram) return 'bg-gradient-to-br from-blue-300 to-indigo-500 text-white';
    return 'bg-gradient-to-br from-rose-300 to-pink-500 text-white';
  }

  static parseIconUrl(account: Account): string | null {
    if (account.recognizedAccount?.iconUrl) return account.recognizedAccount.iconUrl;
    if (account instanceof NftAccount || account instanceof NftHolderAccount || account instanceof NftMetadataAccount) return account.metadata.image ?? null;
    if (account instanceof CandyMachineV2Account || account instanceof CandyMachineAccount || account instanceof CandyMachineConfigAccount) return account.configMetadata?.image ?? null;
    if (account instanceof TokenAccount && account.tokenInfo?.logoURI) return account.tokenInfo.logoURI;
    if (account instanceof TokenMintAccount && account.tokenInfo?.logoURI) return account.tokenInfo.logoURI;
    if (account instanceof SHDWFileAccount || account instanceof SHDWStorageAccount) return account.owner?.recognizedAccount?.iconUrl ?? null;
    return null;
  }

  static parseTabs(account: Account): AccountTab[] {
    const baseTabs = [
      {
        key: 'parsed-data',
        label: 'Parsed Data',
        component: 'account-tab-json-explorer',
        props: { json: (account as DecodedAccount).jsonData },
        when: account instanceof DecodedAccount,
      },
      {
        key: 'token-info',
        label: 'Token Info',
        component: 'account-tab-json-explorer',
        props: { json: (account as TokenAccount | TokenMintAccount).tokenInfo },
        when: (account instanceof TokenAccount || account instanceof TokenMintAccount) && !!account.tokenInfo,
      },
      {
        key:
        'related-accounts',
        label: 'Related Accounts',
        component: 'account-tab-related-accounts',
      },
      {
        key: 'account-info',
        label: 'Account Info',
        component: 'account-tab-json-explorer',
        props: { json: account.parsedAccountInfo },
      },
    ];

    if (account instanceof WalletAccount) {
      return [
        {
          key: 'spl-tokens',
          label: 'Tokens',
          component: 'account-tab-tokens',
        },
        {
          key: 'spl-nfts',
          label: 'NFTs',
          component: 'account-tab-nfts',
        },
        ...baseTabs,
      ];
    }

    if (account instanceof CandyMachineAccount) {
      return [...baseTabs.slice(0, 1), {
        key: 'candy-machine-nfts',
        label: 'Minted NFTs',
        component: 'account-tab-candy-machine-nfts',
      }, ...baseTabs.slice(1)];
    }

    if (account instanceof CandyMachineV2Account) {
      return [
        ...baseTabs.slice(0, 1),
        {
          key: 'candy-machine-config-lines',
          label: 'Mints',
          component: 'account-tab-candy-machine-config-lines',
          when: account.configLines.length > 0,
        },
        {
          key: 'candy-machine-nfts',
          label: 'Minted NFTs',
          component: 'account-tab-candy-machine-nfts',
        },
        ...baseTabs.slice(1),
      ];
    }

    if (account instanceof NftAccount || account instanceof NftHolderAccount) {
      return [
        {
          key: 'nft-metadata-json',
          label: 'JSON Metadata',
          component: 'account-tab-json-explorer',
          props: { json: account.metadata },
        },
        {
          key: 'nft-metadata-chain',
          label: 'On-Chain Metadata',
          component: 'account-tab-json-explorer',
          props: { json: account.metadataAccount.jsonData },
        },
        ...baseTabs,
      ];
    }

    if (account instanceof NftMetadataAccount) {
      return [{
        key: 'nft-metadata-json',
        label: 'JSON Metadata',
        component: 'account-tab-json-explorer',
        props: { json: account.metadata },
      }, ...baseTabs];
    }

    if (account instanceof AnchorProgram) {
      return [
        {
          key: 'anchor-instructions',
          label: 'Instructions',
          component: 'account-tab-anchor-instructions',
        },
        {
          key: 'anchor-accounts',
          label: 'Accounts & Types',
          component: 'account-tab-anchor-accounts',
        },
        {
          key: 'anchor-idl',
          label: 'IDL',
          component: 'account-tab-json-explorer',
          props: { json: account.anchorIdl },
        },
        ...baseTabs,
      ];
    }

    return baseTabs;
  }

  static parseOverview(account: Account): string | null {
    if (account instanceof WalletAccount) return 'account-overview-wallet-account';
    if (account instanceof NftAccount || account instanceof NftHolderAccount || account instanceof NftMetadataAccount) return 'account-overview-nft';
    if (account instanceof TokenAccount) return 'account-overview-token-account';
    if (account instanceof TokenMintAccount) return 'account-overview-token-mint-account';
    if (account instanceof CandyMachineV2Account) return 'account-overview-candy-machine-v2';
    if (account instanceof CandyMachineAccount) return 'account-overview-candy-machine';
    if (account instanceof CandyMachineConfigAccount) return 'account-overview-candy-machine-config';
    if (account instanceof SHDWFileAccount) return 'account-overview-SHDW-file-account';
    if (account instanceof SHDWStorageAccount) return 'account-overview-SHDW-storage-account';
    return null;
  }
}
