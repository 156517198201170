import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
  install: () => {
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
  },
};
