import { AccountsCoder, Idl } from '@project-serum/anchor';
import { AccountInfo } from '@solana/web3.js';

export const identifyAnchorAccount = (accountInfo: AccountInfo<Buffer>, idl: Idl): string | null => {
  if (!idl.accounts) {
    return null;
  }

  const idlAccount = idl.accounts.find(({ name }) => {
    const discriminator = AccountsCoder.accountDiscriminator(name);
    return discriminator.compare(accountInfo.data.slice(0, 8)) === 0;
  });

  return idlAccount?.name ?? null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseAnchorType: any = (anchorType: any, anchorIdl: Idl, extendTypes = false) => {
  if (typeof anchorType === 'string') {
    return anchorType;
  }

  if (anchorType?.kind === 'struct') {
    return (anchorType.fields ?? []).reduce((
      acc: { [key: string]: unknown },
      field: { name: string, type: object },
    ) => {
      acc[field.name] = parseAnchorType(field.type, anchorIdl, extendTypes);
      return acc;
    }, {});
  }

  if (anchorType?.kind === 'enum') {
    const variants = anchorType.variants.map((variant: { name: string }) => variant.name);
    return { '[enum]': variants };
  }

  if (typeof anchorType.defined === 'string') {
    const definedType = extendTypes
      ? (anchorIdl.types?.find((type) => type.name === anchorType.defined)?.type ?? null)
      : null;

    return definedType ? parseAnchorType(definedType, anchorIdl, extendTypes) : { '[type]': anchorType.defined };
  }

  if (typeof anchorType.option !== 'undefined') {
    return { '[option]': parseAnchorType(anchorType.option, anchorIdl, extendTypes) };
  }

  if (typeof anchorType.vec !== 'undefined') {
    return { '[vector]': parseAnchorType(anchorType.vec, anchorIdl, extendTypes) };
  }

  if (typeof anchorType.array === 'object') {
    return {
      '[array]': parseAnchorType(anchorType.array[0], anchorIdl, extendTypes),
      '[size]': anchorType.array[1],
    };
  }

  return anchorType;
};

interface AnchorTypeSummary {
  text: string,
  html: string,
  hasMore: boolean,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseAnchorTypeSummary = (anchorType: any): AnchorTypeSummary => {
  if (typeof anchorType === 'string') {
    return {
      text: anchorType,
      html: `<span class="text-green-600">${anchorType}</span>`,
      hasMore: false,
    };
  }

  if (anchorType?.kind === 'struct') {
    const text = `struct${anchorType?.fields?.length ?? 0}`;
    return { text, html: text, hasMore: true };
  }

  if (anchorType?.kind === 'enum') {
    const text = `enum${anchorType?.variants?.length ?? 0}`;
    return { text, html: text, hasMore: true };
  }

  if (typeof anchorType.defined === 'string') {
    return {
      text: anchorType.defined,
      html: `<span class="text-orange-500">${anchorType.defined}</span>`,
      hasMore: true,
    };
  }

  if (typeof anchorType.option !== 'undefined') {
    const nested = parseAnchorTypeSummary(anchorType.option);
    return {
      text: `option<${nested.text}>`,
      html: `<span class="text-indigo-500">option</span><${nested.html}<span class="text-indigo-500">></span>`,
      hasMore: nested.hasMore,
    };
  }

  if (typeof anchorType.vec !== 'undefined') {
    const nested = parseAnchorTypeSummary(anchorType.vec);
    return {
      text: `vec<${nested.text}>`,
      html: `<span class="text-indigo-500">vec<</span>${nested.html}<span class="text-indigo-500">></span>`,
      hasMore: nested.hasMore,
    };
  }

  if (typeof anchorType.array === 'object') {
    const nested = parseAnchorTypeSummary(anchorType.array[0]);
    return {
      text: `[${anchorType.array[1]}, ${nested.text}]`,
      html: `<span class="text-indigo-500">[${anchorType.array[1]}, </span>${nested.html}<span class="text-indigo-500">]</span>`,
      hasMore: nested.hasMore,
    };
  }

  const text = anchorType?.kind ?? anchorType?.type ?? 'Custom';
  return { text, html: text, hasMore: true };
};
