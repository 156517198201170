import { Account } from '@/models';

export class Loader {
  public readonly account: Account;

  public state: 'idle' | 'loading' | 'success' | 'error' = 'idle';

  public error: unknown = null;

  constructor(account: Account) {
    this.account = account;
  }

  async reload() {
    if (this.loading) return;
    try {
      this.state = 'loading';
      await this.handle();
      this.state = 'success';
    } catch (error) {
      this.state = 'error';
      this.error = error;
    }
  }

  async load() {
    if (this.loaded) return;
    await this.reload();
  }

  // eslint-disable-next-line class-methods-use-this
  async handle() {
    throw new Error('The handle method has not been implement for this loader.');
  }

  get loading() {
    return this.state === 'loading';
  }

  get loaded() {
    return this.state !== 'idle';
  }

  get successful() {
    return this.state === 'success';
  }
}
