import { PublicKey } from '@solana/web3.js';
import { Account } from '@/models';

export type AccountAddress = string | PublicKey | Account;

export const toPublicKey = (address: AccountAddress): PublicKey => {
  if (address instanceof Account) return address.publicKey;
  if (address instanceof PublicKey) return address;
  return new PublicKey(address);
};

export const toPublicKeyAsString = (address: AccountAddress): string => {
  if (address instanceof Account) return address.publicKey.toBase58();
  if (address instanceof PublicKey) return address.toBase58();
  return address;
};

export const shortenPublicKey = (address: AccountAddress, start = 4, end = 4): string => {
  address = toPublicKey(address).toBase58();
  return `${address.slice(0, start)}..${address.slice(-end)}`;
};
