import { Layout, blob, Blob } from '@solana/buffer-layout';
import BN from 'bn.js';

export class BNLayout extends Layout<BN> {
  blob: Blob;

  signed: boolean;

  constructor(span: number, signed: boolean, property?: string) {
    super(span, property);
    this.blob = blob(span);
    this.signed = signed;
  }

  decode(b: Buffer, offset = 0) {
    const num = new BN(this.blob.decode(b, offset), 10, 'le');
    if (this.signed) {
      return num.fromTwos(this.span * 8).clone();
    }
    return num;
  }

  encode(src: BN, b: Buffer, offset = 0) {
    if (this.signed) {
      src = src.toTwos(this.span * 8);
    }
    return this.blob.encode(src.toArrayLike(Buffer, 'le', this.span), b, offset);
  }
}

export const bnu8 = (property?: string): BNLayout => new BNLayout(1, false, property);
export const bnu16 = (property?: string): BNLayout => new BNLayout(2, false, property);
export const bnu24 = (property?: string): BNLayout => new BNLayout(3, false, property);
export const bnu32 = (property?: string): BNLayout => new BNLayout(4, false, property);
export const bnu40 = (property?: string): BNLayout => new BNLayout(5, false, property);
export const bnu48 = (property?: string): BNLayout => new BNLayout(6, false, property);
export const bnu56 = (property?: string): BNLayout => new BNLayout(7, false, property);
export const bnu64 = (property?: string): BNLayout => new BNLayout(8, false, property);
export const bnu128 = (property?: string): BNLayout => new BNLayout(16, false, property);

export const bni8 = (property?: string): BNLayout => new BNLayout(1, true, property);
export const bni16 = (property?: string): BNLayout => new BNLayout(2, true, property);
export const bni24 = (property?: string): BNLayout => new BNLayout(3, true, property);
export const bni32 = (property?: string): BNLayout => new BNLayout(4, true, property);
export const bni40 = (property?: string): BNLayout => new BNLayout(5, true, property);
export const bni48 = (property?: string): BNLayout => new BNLayout(6, true, property);
export const bni56 = (property?: string): BNLayout => new BNLayout(7, true, property);
export const bni64 = (property?: string): BNLayout => new BNLayout(8, true, property);
export const bni128 = (property?: string): BNLayout => new BNLayout(16, true, property);
