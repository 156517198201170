import { AccountInfo, PublicKey } from '@solana/web3.js';
import { useWorkspace } from '@/composables';
import { AccountContext } from '@/models';
import {
  AccountAddress, Cache, toPublicKey, zipMap,
} from '@/utils';

export const accountContextCache = new Cache<PublicKey, AccountContext>(
  1000,
  (k: PublicKey, v: AccountContext) => v.publicKey.toBase58() === k.toBase58(),
);

export const fetchAccountContext = async (address: AccountAddress): Promise<AccountContext | null> => {
  try {
    const publicKey = toPublicKey(address);
    const cachedContext = accountContextCache.get(publicKey);
    if (cachedContext) {
      return cachedContext;
    }

    const { connection } = useWorkspace();
    console.log('Fetching...', publicKey.toBase58());
    const accountInfo = await connection.value.getAccountInfo(publicKey);
    const fetchedContext = accountInfo ? { publicKey, accountInfo } : null;
    if (fetchedContext) {
      accountContextCache.add(publicKey, fetchedContext);
    }

    return fetchedContext;
  } catch (error) {
    return null;
  }
};

interface MultipleAccountDictionaryEntry {
  publicKey: PublicKey | null,
  duplicateIndex: number,
  cachedContext: AccountContext | null,
}

export const fetchMultipleAccountContexts = async (addresses: (AccountAddress | null)[]): Promise<(AccountContext | null)[]> => {
  // Get all PublicKeys (or null) from AccountAddresses.
  const publicKeys = addresses.map((address: AccountAddress | null) => {
    try {
      if (!address) return null;
      return toPublicKey(address);
    } catch (error) {
      return null;
    }
  });

  // Create a dictionary to identify cached contexts and duplicated keys.
  const dictionary = publicKeys.reduce((acc, publicKey) => {
    const duplicateIndex = acc.findIndex((entry) => entry.publicKey && entry.publicKey.toBase58() === publicKey?.toBase58());
    const cachedContext = (duplicateIndex === -1 && publicKey)
      ? accountContextCache.get(publicKey)
      : null;
    acc.push({ publicKey, duplicateIndex, cachedContext });
    return acc;
  }, [] as MultipleAccountDictionaryEntry[]);

  // Defines whether a given dictionary entry should be fetched.
  const shouldBeFetched = (entry: MultipleAccountDictionaryEntry): boolean => (
    entry.duplicateIndex === -1 && !entry.cachedContext && entry.publicKey !== null
  );

  // Get all PublicKeys that should be fetched.
  // We only take the first 100 as getMultipleAccountsInfo will fail after that.
  // When fetching accounts, chunking need to happen at a higher level.
  const publicKeysToFetch: PublicKey[] = dictionary
    .filter(shouldBeFetched)
    .map((entry) => entry.publicKey as PublicKey)
    .slice(0, 100);

  try {
    // Fetch AccountInfos.
    const { connection } = useWorkspace();
    let fetchedAccountInfos: (AccountInfo<Buffer> | null)[] = [];
    if (publicKeysToFetch.length > 0) {
      console.log('Fetching...', publicKeysToFetch.map((x) => x.toBase58()));
      fetchedAccountInfos = await connection.value.getMultipleAccountsInfo(publicKeysToFetch);
    }

    // Wrap AccountInfos (or null) into AccountContexts.
    const fetchedContexts: (AccountContext | null)[] = zipMap(publicKeysToFetch, fetchedAccountInfos, (publicKey, accountInfo) => {
      if (!accountInfo) return null;
      return { publicKey, accountInfo };
    });

    // Add fetched contexts to cache.
    fetchedContexts.forEach((context) => {
      if (!context) return;
      accountContextCache.add(context.publicKey, context);
    });

    // Reconciliate AccountContexts (or null) with the original array of AccountAddresses.
    const { reconciliatedContexts } = dictionary.reduce((acc, entry) => {
      const wasFetched = shouldBeFetched(entry);
      let context = null;
      if (wasFetched) {
        context = acc.fetchedContexts.shift() ?? null;
      } else if (entry.duplicateIndex >= 0) {
        context = acc.reconciliatedContexts?.[entry.duplicateIndex] ?? null;
      } else if (entry.cachedContext) {
        context = entry.cachedContext;
      }
      acc.reconciliatedContexts.push(context);
      return acc;
    }, { reconciliatedContexts: [] as (AccountContext | null)[], fetchedContexts: fetchedContexts.slice() });

    return reconciliatedContexts;
  } catch (error) {
    return addresses.map(() => null);
  }
};
