import { App } from 'vue';
import router from './router';
import registerComponents from './register-components';
import dates from './dates';

export default {
  install: (app: App) => {
    app.use(router);
    app.use(dates);
    app.use(registerComponents);
  },
};
