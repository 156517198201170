import { Layout, u8, UInt } from '@solana/buffer-layout';
import { WrappedLayout } from './WrappedLayout';

function decodeBool(value: number): boolean {
  if (value === 0) {
    return false;
  } if (value === 1) {
    return true;
  }
  throw new Error(`Invalid bool: ${value}`);
}

function encodeBool(value: boolean): number {
  return value ? 1 : 0;
}

export function bool(property?: string, discriminator?: UInt): Layout<boolean> {
  discriminator = discriminator ?? u8();
  return new WrappedLayout(discriminator, decodeBool, encodeBool, property);
}
