import { Account, AccountContext } from '@/models';
import { fetchAccountContext, fetchMultipleAccountContexts } from './fetchAccountContext';
import { detectAccount } from './detectAccount';
import { AccountAddress, zipMap } from '@/utils';

export const fetchAccount = async (address: AccountAddress, owner: Account | null | undefined = undefined): Promise<Account | null> => {
  // Fetch the account info.
  const accountContext = await fetchAccountContext(address);
  if (!accountContext) return null;

  // Fetch the owner account unless provided.
  if (owner === undefined) {
    const ownerContext = await fetchAccountContext(accountContext.accountInfo.owner);
    owner = ownerContext ? new Account(ownerContext, null) : null;
    owner = owner ? (await detectAccount(owner)) : null;
  }

  // Detect the account.
  const account = new Account(accountContext, owner);
  return detectAccount(account);
};

export type FetchMultipleAccountsPipe = (accountsWithOwner: ({ account: AccountContext | null, owner: AccountContext | null }[])) => Promise<void>

export const fetchMultipleAccounts = async (addresses: AccountAddress[], pipe: FetchMultipleAccountsPipe | undefined = undefined): Promise<Promise<Account | null>[]> => {
  pipe = pipe ?? (() => Promise.resolve());
  const accountContexts = await fetchMultipleAccountContexts(addresses);
  const ownerPublicKeys = accountContexts.map((context) => context?.accountInfo?.owner ?? null);
  const ownerContexts = await fetchMultipleAccountContexts(ownerPublicKeys);

  const accountsWithOwner = zipMap(accountContexts, ownerContexts, (account, owner) => ({ account, owner }));
  await pipe(accountsWithOwner);

  return accountsWithOwner.map(async ({ account, owner }) => {
    if (!account) return null;
    const detectedOwner = owner ? (await detectAccount(new Account(owner, null))) : null;
    const detectedAccount = await detectAccount(new Account(account, detectedOwner));
    return detectedAccount;
  });
};

export const fetchMultipleAccountsSettled = async (addresses: AccountAddress[], pipe: FetchMultipleAccountsPipe | undefined = undefined): Promise<(Account | null)[]> => {
  const promises = await fetchMultipleAccounts(addresses, pipe);
  const promiseResults = await Promise.allSettled(promises);
  return promiseResults.map((result) => (result.status === 'fulfilled' ? result.value : null));
};
