import {
  Account, AnchorAccount, CandyMachineV2Account, NftMetadata,
} from '@/models';
import { AccountDetector } from '.';
import { fetchJson, getCandyMachineConfigLines, metaplexConstants } from '@/utils';

const isCandyMachineV2 = (account: Account): boolean => account instanceof AnchorAccount
  && (account.owner?.isRecognized('candyMachineV2Program') ?? false)
  && account.anchorAccountName === 'CandyMachine';

export const candyMachineV2Detector: AccountDetector = async (account, next) => {
  if (!(account instanceof AnchorAccount)) return next(account);
  if (!isCandyMachineV2(account)) return next(account);

  const configLines = getCandyMachineConfigLines(account, metaplexConstants.CONFIG_ARRAY_START_V2);
  const configLineMetadata = configLines.length > 0 ? await fetchJson<NftMetadata>(configLines[0].uri) : null;

  return new CandyMachineV2Account(
    account.context,
    account.owner,
    account.decodedData,
    account.anchorProgram,
    account.anchorAccountName,
    configLines,
    configLineMetadata,
  );
};
