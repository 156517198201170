import { PublicKey } from '@solana/web3.js';
import { startCase } from 'lodash-es';

export class RelatedAccount {
  public readonly publicKey: PublicKey;

  public readonly relationshipKey: string;

  public readonly relationshipName: string;

  public readonly relationshipDescription: string | null;

  constructor(
    publicKey: PublicKey,
    relationshipKey: string,
    relationshipName: string | null = null,
    relationshipDescription: string | null = null,
  ) {
    this.publicKey = publicKey;
    this.relationshipKey = relationshipKey;
    this.relationshipName = relationshipName === null ? startCase(relationshipKey) : relationshipName;
    this.relationshipDescription = relationshipDescription;
  }

  rename(newName: string | null, newDescription: string | null = null): RelatedAccount {
    newName = newName ?? this.relationshipName;
    newDescription = newDescription ?? this.relationshipDescription;
    return new RelatedAccount(this.publicKey, this.relationshipKey, newName, newDescription);
  }
}
