<template>
  <div class="flex flex-col -mx-8 -mt-2 -mb-8 relative">
    <div class="overflow-x-auto">
      <div class="px-8 pt-2 pb-8 align-middle inline-block min-w-full">
        <slot></slot>
      </div>
    </div>
    <div class="absolute inset-y-0 left-0 w-8" style="background-image: linear-gradient(to right, #fff 30%, rgb(255 255 255 / 0) 100%)"></div>
    <div class="absolute inset-y-0 right-0 w-8" style="background-image: linear-gradient(to left, #fff 30%, rgb(255 255 255 / 0) 100%)"></div>
  </div>
</template>
