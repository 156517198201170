import { PublicKey, SystemProgram } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { isArray, isPlainObject, mapValues } from 'lodash-es';
import BN from 'bn.js';
import { AccountAddress, toPublicKey } from '.';

export interface RecognizedAccount {
  publicKey: PublicKey,
  name: string,
  key: string,
  icon?: string,
  iconClass?: string,
  iconUrl?: string,
}

const systemIconClass = 'bg-gradient-to-br from-gray-500 to-gray-800 text-white';
const metaplexLogo = 'https://user-images.githubusercontent.com/3642397/148202380-3467d2e8-6475-40a2-bdd3-2901648b33f6.jpg';
const genesysgoLogo = 'https://www.gitbook.com/cdn-cgi/image/width=40,height=40,fit=contain,dpr=2,format=auto/https%3A%2F%2F4277696325-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FRZnDkLYguwEqGu1PYFcE%252Ficon%252FET8DVoCDZstvc7iOdBZa%252F0_iss9ZG_400x400.jpg%3Falt%3Dmedia%26token%3D96631597-4b59-4baa-9d50-9783b73b77a7';

export const recognizedAccounts: RecognizedAccount[] = [
  {
    publicKey: SystemProgram.programId,
    name: 'System Program',
    key: 'systemProgram',
    iconClass: systemIconClass,
  },
  {
    publicKey: new PublicKey('BPFLoaderUpgradeab1e11111111111111111111111'),
    name: 'BPF Loader Upgradable Program',
    key: 'bpfLoaderUpgradableProgram',
    iconClass: systemIconClass,
  },
  {
    publicKey: new PublicKey('NativeLoader1111111111111111111111111111111'),
    name: 'Native Loader Program',
    key: 'nativeLoaderProgram',
    iconClass: systemIconClass,
  },
  {
    publicKey: TOKEN_PROGRAM_ID,
    name: 'Token Program',
    key: 'tokenProgram',
    iconClass: systemIconClass,
  },
  {
    publicKey: new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'),
    name: 'Associated Token Account Program',
    key: 'associatedTokenAccountProgram',
    iconClass: systemIconClass,
  },
  {
    publicKey: new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
    name: 'Metaplex Token Metadata Program',
    key: 'tokenMetadataProgram',
    iconUrl: metaplexLogo,
  },
  {
    publicKey: new PublicKey('cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ'),
    name: 'Metaplex Candy Machine Program',
    key: 'candyMachineProgram',
    iconUrl: metaplexLogo,
  },
  {
    publicKey: new PublicKey('cndy3Z4yapfJBmL3ShUp5exZKqR3z33thTzeNMm2gRZ'),
    name: 'Metaplex Candy Machine V2 Program',
    key: 'candyMachineV2Program',
    iconUrl: metaplexLogo,
  },
  {
    publicKey: new PublicKey('2e1wdyNhUvE76y6yUCvah2KaviavMJYKoRun8acMRBZZ'),
    name: 'ShadowDrive Program',
    key: 'shadowDriveProgram',
    iconUrl: genesysgoLogo,
  },
];

export const accountDictionary = recognizedAccounts.reduce((acc, account) => {
  acc[account.publicKey.toBase58()] = account;
  return acc;
}, {} as { [name: string]: RecognizedAccount });

export const accountPublicKeys = recognizedAccounts.reduce((acc, account) => {
  acc[account.key] = account.publicKey;
  return acc;
}, {} as { [name: string]: PublicKey });

export const parseStringForJson = (string: string): string | number => {
  const float = parseFloat(string);
  if (Number.isNaN(float)) return string;
  if (string === `${float}`) return float;
  return string;
};

// eslint-disable-next-line arrow-body-style
export const parseDecodedDataAsJson = (data: object): object => {
  return mapValues(data, (value: unknown) => {
    if (value instanceof Uint8Array) return value.toString();
    if (value instanceof BN || value instanceof Uint8Array) return parseStringForJson(value.toString());
    if (value instanceof PublicKey) return value.toString();
    if (isPlainObject(value) || isArray(value)) return parseDecodedDataAsJson(value as object);
    return value;
  });
};

export const getAccountRoute = (address: AccountAddress): object => {
  let stringAddress : string;
  try {
    stringAddress = toPublicKey(address).toBase58();
  } catch (error) {
    stringAddress = typeof address === 'string' ? address : '';
  }

  return ({
    name: 'account',
    params: { address: stringAddress },
  });
};
