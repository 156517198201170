import { WalletAccount } from '@/models';
import { AccountDetector } from '.';

export const walletAccountDetector: AccountDetector = async (account, next) => {
  if (account.accountInfo.executable || !account.isOnCurve()) return next(account);
  if (!account.owner?.isRecognized('systemProgram')) return next(account);

  return new WalletAccount(
    account.context,
    account.owner,
  );
};
