<script lang="ts" setup>
</script>

<template>
  <header class="pb-32 bg-cover bg-center bg-[url('assets/gradient.png')]">
    <nav class="container py-6">
      <div class="flex flex-wrap items-center justify-between -m-3">
        <div class="flex flex-wrap items-center text-pink-100">
          <!-- <router-link :to="{ name: 'home' }" class="block" active-class="font-semibold text-white">Home</router-link> -->
          <router-link :to="{ name: 'account' }" class="block m-3" active-class="font-semibold text-white">Account</router-link>
          <button data-feedback-fish class="hover:text-white m-3">Feedback</button>
        </div>
        <div class="m-3">
          <the-workspace-button></the-workspace-button>
        </div>
      </div>
    </nav>
  </header>
</template>
