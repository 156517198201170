import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { clusterApiUrl } from '@solana/web3.js';
import { GENESYSGO_DEVNET, GENESYSGO_MAINNET, useWorkspace } from '@/composables';
// import HomeView from '@/views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'account' },
    // component: HomeView,
  },
  {
    path: '/address/:address?',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '@/views/AccountView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (!to.query?.cluster) return;
  const { cluster } = useWorkspace();

  switch (to.query.cluster) {
    case 'mainnet':
      cluster.value = clusterApiUrl('mainnet-beta');
      break;
    case 'mainnet-genesysgo':
      cluster.value = GENESYSGO_MAINNET;
      break;
    case 'testnet':
      cluster.value = clusterApiUrl('testnet');
      break;
    case 'devnet':
      cluster.value = clusterApiUrl('devnet');
      break;
    case 'devnet-genesysgo':
      cluster.value = GENESYSGO_DEVNET;
      break;
    case 'local':
      cluster.value = 'http://127.0.0.1:8899';
      break;
    default:
      console.warn(`Unknown cluster: ${to.query.cluster}. Ignoring...`);
      break;
  }
});

export default router;
