import { Program } from '@project-serum/anchor';
import { Account, AccountContext } from './AccountModel';
import { DecodedAccountData } from './DecodedAccount';
import { AnchorAccount } from './AnchorAccount';
import { CandyMachineConfigAccount } from './CandyMachineConfigAccount';
import { TokenAmount } from './TokenAmount';
import { NftMetadata } from './NftMetadataAccount';

export class CandyMachineAccount extends AnchorAccount {
  public readonly configAccount: CandyMachineConfigAccount | null;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: DecodedAccountData,
    anchorProgram: Program,
    anchorAccountName: string,
    configAccount: CandyMachineConfigAccount | null,
  ) {
    super(accountContext, owner, decodedData, anchorProgram, anchorAccountName);
    this.configAccount = configAccount;
  }

  get mintPrice(): TokenAmount {
    return new TokenAmount(this.decodedData.data.price, 9, 'SOL');
  }

  get configMetadata(): NftMetadata | null {
    return this.configAccount?.configMetadata ?? null;
  }
}
