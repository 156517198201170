import { NftAccount, NftHolderAccount, TokenAccount } from '@/models';
import { AccountDetector } from '.';

export const nftHolderAccountDetector: AccountDetector = async (account, next) => {
  if (!(account instanceof TokenAccount)) return next(account);
  if (!(account.tokenMintAccount instanceof NftAccount)) return next(account);

  return new NftHolderAccount(
    account.context,
    account.owner,
    account.decodedData,
    account.tokenMintAccount,
  );
};
