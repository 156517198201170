/* eslint-disable @typescript-eslint/no-explicit-any */
export class JsonExplorerNode {
  public readonly value: any;

  public readonly key: string | null;

  public readonly path: string;

  public readonly indent: number;

  public readonly type: string;

  public opened: boolean;

  public children: JsonExplorerNode[];

  public wrapMode: boolean;

  public overflowing: boolean;

  constructor(value: any = '', key: string | null = null, parentPath = '', indent = 0, depth = 0) {
    this.value = value;
    this.key = key;
    this.path = (parentPath ? `${parentPath}.` : '') + (key || '');
    this.indent = indent;
    this.type = this.parseType();

    this.opened = indent < depth;
    this.children = this.parseChildren();
    this.wrapMode = !this.hasKey && indent === 0 && !this.hasChildren;
    this.overflowing = false;
  }

  parseType(): string {
    if (this.value === null) return 'null';
    if (Array.isArray(this.value)) return 'array';
    return typeof this.value;
  }

  parseChildren(): JsonExplorerNode[] {
    if (!this.isCollection) return [];
    return this.getKeys().map((key) => this.createChild(this.value[key], key));
  }

  getKeys(): string[] {
    return Object.keys(this.value);
  }

  createChild(value: any, key: string | null): JsonExplorerNode {
    return new JsonExplorerNode(value, key, this.path, this.indent + 1);
  }

  get hasKey(): boolean { return this.key !== null; }

  get hasChildren(): boolean { return this.children.length > 0; }

  get isArray(): boolean { return this.type === 'array'; }

  get isObject(): boolean { return this.type === 'object'; }

  get isNumber(): boolean { return this.type === 'number'; }

  get isString(): boolean { return this.type === 'string'; }

  get isBoolean(): boolean { return this.type === 'boolean'; }

  get isNull(): boolean { return this.type === 'null'; }

  get isCollection(): boolean { return this.isArray || this.isObject; }

  get copyValue(): string {
    if (this.isString) return this.value || '""';
    return JSON.stringify(this.value);
  }

  get displayValue(): string {
    if (this.isNull) return 'null';
    if (this.isString && !this.value) return '""';
    if (this.isObject) return `${this.indent > 0 ? '' : 'Object '}{${this.children.length}}`;
    if (this.isArray) return `${this.indent > 0 ? '' : 'Array '}[${this.children.length}]`;
    return this.value;
  }

  get previewDisplayValue(): string {
    if (this.isObject) return '{...}';
    if (this.isArray) return '[...]';
    return this.displayValue;
  }

  get displayClasses(): string {
    if (this.isObject || this.isArray) return 'italic text-gray-500 flex-shrink-0';
    if (this.isString) return `text-green-600 ${this.wrapMode ? 'whitespace-pre-wrap' : 'no-wrap'}`;
    if (this.isNumber) return 'text-orange-500';
    if (this.isBoolean) return 'text-pink-500';
    if (this.isNull) return 'text-pink-700';
    return '';
  }

  get preview(): string {
    if (this.isArray) {
      const content = this.children
        .slice(0, 50)
        .map((child) => child.previewDisplayValue).join(', ');

      return `[${content}]`;
    }

    if (this.isObject) {
      const content = this.children
        .slice(0, 50)
        .map((child) => `${child.key}:${child.previewDisplayValue}`).join(', ');

      return `{${content}}`;
    }

    return this.displayValue;
  }

  open(): void {
    this.opened = true;
  }

  close(): void {
    this.opened = false;
    this.children.forEach((child) => child.close());
  }

  toggle(): void {
    return this.opened ? this.close() : this.open();
  }

  getLeftPadding(indent: number | undefined) {
    indent = indent === undefined ? this.indent : indent;

    return 1 + indent * 2;
  }

  setOverflowing(overflowing: boolean) {
    if (this.isCollection) return;
    if (this.overflowing === overflowing) return;
    this.overflowing = overflowing;

    if (overflowing) {
      const child = this.createChild(this.value, null);
      child.wrapMode = true;
      this.children = [child];
    } else {
      this.children = [];
    }
  }
}
