<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="192"
    height="192"
    fill="currentColor"
    viewBox="0 0 256 256"
  >
    <rect width="256" height="256" fill="none"></rect>
    <rect
      x="100"
      y="100"
      width="56"
      height="56"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></rect>
    <rect
      x="48"
      y="48"
      width="160"
      height="160"
      rx="8"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></rect>
    <line
      x1="208"
      y1="104"
      x2="232"
      y2="104"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="208"
      y1="152"
      x2="232"
      y2="152"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="24"
      y1="104"
      x2="48"
      y2="104"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="24"
      y1="152"
      x2="48"
      y2="152"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="152"
      y1="208"
      x2="152"
      y2="232"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="104"
      y1="208"
      x2="104"
      y2="232"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="152"
      y1="24"
      x2="152"
      y2="48"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
    <line
      x1="104"
      y1="24"
      x2="104"
      y2="48"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="24"
    ></line>
  </svg>
</template>
