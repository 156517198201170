import { Idl, Program } from '@project-serum/anchor';
import { AccountContext, Account } from './AccountModel';
import { Program as BaseProgram } from './Program';

export class AnchorProgram extends BaseProgram {
  public readonly anchorProgram: Program;

  public readonly anchorIdl: Idl;

  constructor(accountContext: AccountContext, owner: Account | null, anchorProgram: Program) {
    super(accountContext, owner);
    this.anchorProgram = anchorProgram;
    this.anchorIdl = anchorProgram.idl;
  }
}
