import { TokenAccountCoder } from '@/coders';
import { TokenAccount, TokenMintAccount } from '@/models';
import { AccountDetector } from '.';
import { fetchAccount } from '../fetchAccount';

export const tokenAccountDetector: AccountDetector = async (account, next) => {
  if (!account.owner?.isRecognized('tokenProgram')) return next(account);
  const decodedData = TokenAccountCoder.tryDecode(account.accountInfo.data);
  if (!decodedData) return next(account);

  const tokenMintAccount = await fetchAccount(decodedData.mint);
  if (!(tokenMintAccount instanceof TokenMintAccount)) return next(account);

  return next(new TokenAccount(account.context, account.owner, decodedData, tokenMintAccount));
};
