import { useLocalStorage } from '@vueuse/core';
import {
  Connection, clusterApiUrl, PublicKey, Transaction,
} from '@solana/web3.js';
import { Provider } from '@project-serum/anchor';
import {
  computed, ref, Ref, watch,
} from 'vue';
import { accountPublicKeys } from '@/utils';
import { accountContextCache, detectAccountCache } from '@/api';

interface AnchorWallet {
  publicKey: PublicKey;
  signTransaction(transaction: Transaction): Promise<Transaction>;
  signAllTransactions(transactions: Transaction[]): Promise<Transaction[]>;
}

export type ClusterName = 'Mainnet' | 'GenesysGo Mainnet' | 'Testnet' | 'Devnet' | 'GenesysGo Devnet' | 'Localhost' | 'Custom';

export const GENESYSGO_MAINNET = 'https://ssc-dao.genesysgo.net';
export const GENESYSGO_DEVNET = 'https://devnet.genesysgo.net';

interface WorkspaceStore {
  accounts: { [name: string]: PublicKey },
  cluster: Ref<string>,
  clusterName: Ref<ClusterName>,
  connection: Ref<Connection>,
  provider: Ref<Provider>,
}

let workspaceStore: WorkspaceStore | null = null;

export const useWorkspace = (): WorkspaceStore => {
  if (!workspaceStore) {
    throw new Error('Workspace not initialized.');
  }
  return workspaceStore;
};

export const initWorkspace = async (): Promise<void> => {
  const cluster = ref(GENESYSGO_MAINNET);
  useLocalStorage('cluster', cluster);
  const clusterName = computed(() => {
    if (cluster.value === clusterApiUrl('mainnet-beta')) return 'Mainnet';
    if (cluster.value === GENESYSGO_MAINNET) return 'GenesysGo Mainnet';
    if (cluster.value === clusterApiUrl('testnet')) return 'Testnet';
    if (cluster.value === clusterApiUrl('devnet')) return 'Devnet';
    if (cluster.value === GENESYSGO_DEVNET) return 'GenesysGo Devnet';
    if (cluster.value === 'http://127.0.0.1:8899') return 'Localhost';
    return 'Custom';
  });

  watch(cluster, () => {
    accountContextCache.clear();
    detectAccountCache.clear();
  });

  const connection = computed(() => new Connection(cluster.value));
  const provider = computed(() => new Provider(connection.value, {} as AnchorWallet, {}));

  workspaceStore = {
    accounts: accountPublicKeys,
    cluster,
    clusterName,
    connection,
    provider,
  };
};
