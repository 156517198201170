import {
  Account, AnchorAccount, CandyMachineConfigAccount, NftMetadata,
} from '@/models';
import { AccountDetector } from '.';
import { metaplexConstants, getCandyMachineConfigLines, fetchJson } from '@/utils';

const isCandyMachineConfig = (account: Account): boolean => account instanceof AnchorAccount
  && (account.owner?.isRecognized('candyMachineProgram') ?? false)
  && account.anchorAccountName === 'Config';

export const candyMachineConfigDetector: AccountDetector = async (account, next) => {
  if (!(account instanceof AnchorAccount)) return next(account);
  if (!isCandyMachineConfig(account)) return next(account);

  const configLines = getCandyMachineConfigLines(account, metaplexConstants.CONFIG_ARRAY_START);
  const configLineMetadata = configLines.length > 0 ? await fetchJson<NftMetadata>(configLines[0].uri) : null;

  return new CandyMachineConfigAccount(
    account.context,
    account.owner,
    account.decodedData,
    account.anchorProgram,
    account.anchorAccountName,
    configLines,
    configLineMetadata,
  );
};
