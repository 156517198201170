import { PublicKey } from '@solana/web3.js';
import {
  struct, publicKey, u8, u16, bool, str, vec, option, WrappedLayout,
} from './layouts';
import { Coder } from './Coder';
import { metaplexConstants, padEmptyChars, removeEmptyChars } from '@/utils';

export interface NftMetadataAccountDataCreator {
  address: PublicKey,
  verified: boolean,
  share: number,
}

export interface NftMetadataAccountDataData {
  name: string,
  symbol: string,
  uri: string,
  sellerFeeBasisPoints: number,
  creators: NftMetadataAccountDataCreator[] | null,
}

export interface NftMetadataAccountData {
  key: number;
  publicKey: PublicKey;
  mint: PublicKey;
  data: NftMetadataAccountDataData;
}

const rawLayout = struct<NftMetadataAccountData>([
  u8('key'),
  publicKey('updateAuthority'),
  publicKey('mint'),
  struct<NftMetadataAccountDataData>([
    str('name'),
    str('symbol'),
    str('uri'),
    u16('sellerFeeBasisPoints'),
    option<NftMetadataAccountDataCreator[]>(vec(struct([
      publicKey('address'),
      bool('verified'),
      u8('share'),
    ])), 'creators'),
  ], 'data'),
]);

const layout = new WrappedLayout(
  rawLayout,
  (raw: NftMetadataAccountData): NftMetadataAccountData => ({
    ...raw,
    data: {
      ...raw.data,
      name: removeEmptyChars(raw.data.name),
      uri: removeEmptyChars(raw.data.uri),
      symbol: removeEmptyChars(raw.data.symbol),
    },
  }),
  (raw: NftMetadataAccountData): NftMetadataAccountData => ({
    ...raw,
    data: {
      ...raw.data,
      name: padEmptyChars(raw.data.name, metaplexConstants.MAX_NAME_LENGTH),
      uri: padEmptyChars(raw.data.uri, metaplexConstants.MAX_URI_LENGTH),
      symbol: padEmptyChars(raw.data.symbol, metaplexConstants.MAX_SYMBOL_LENGTH),
    },
  }),
);

export const NftMetadataCoder = new Coder<NftMetadataAccountData>(layout);
