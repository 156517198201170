import { Coder } from '@project-serum/anchor';
import {
  Account, SHDWStorageAccount,
} from '@/models';
import { AccountDetector } from '.';
import { IDL } from '@/utils';

const isSHDWStorageAccount = (account: Account): boolean => (account.owner?.isRecognized('shadowDriveProgram') ?? false)
  && (account.accountInfo.data.length > 125);

export const shdwStorageAccountDetector: AccountDetector = async (account, next) => {
  if (!isSHDWStorageAccount(account)) return next(account);

  const coder = new Coder(IDL);
  const data = coder.accounts.decode('StorageAccount', account.accountInfo.data);

  return new SHDWStorageAccount(
    account.context,
    account.owner,
    data,
    data.initCounter - data.delCounter,
  );
};
