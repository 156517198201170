import { TokenAccountData } from '@/coders';
import { Account, AccountContext } from './AccountModel';
import { TokenAccount } from './TokenAccount';
import { NftAccount } from './NftAccount';
import { NftMetadata, NftMetadataAccount } from './NftMetadataAccount';

export class NftHolderAccount extends TokenAccount {
  public readonly tokenMintAccount: NftAccount;

  constructor(
    accountContext: AccountContext,
    owner: Account | null,
    decodedData: TokenAccountData,
    tokenMintAccount: NftAccount,
  ) {
    super(accountContext, owner, decodedData, tokenMintAccount);
    this.tokenMintAccount = tokenMintAccount;
  }

  get metadata(): NftMetadata {
    return this.tokenMintAccount.metadata;
  }

  get metadataAccount(): NftMetadataAccount {
    return this.tokenMintAccount.metadataAccount;
  }
}
