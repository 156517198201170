import { Account } from '@/models';
import { asyncPipeline, Cache } from '@/utils';
import {
  AccountDetector,
  anchorProgramDetector,
  anchorAccountDetector,
  candyMachineConfigDetector,
  candyMachineDetector,
  candyMachineV2Detector,
  nftAccountDetector,
  nftHolderAccountDetector,
  nftMetadataAccountDetector,
  programDetector,
  shdwFileAccountDetector,
  shdwStorageAccountDetector,
  tokenAccountDetector,
  tokenMintAccountDetector,
  walletAccountDetector,
} from './accountDetectors';

export const accountDetectors: AccountDetector[] = [
  // Generic.
  programDetector,
  walletAccountDetector,

  // Token Program.
  tokenAccountDetector,
  tokenMintAccountDetector,
  nftAccountDetector,
  nftHolderAccountDetector,
  nftMetadataAccountDetector,

  // Anchor.
  anchorProgramDetector,
  anchorAccountDetector,
  candyMachineV2Detector,
  candyMachineDetector,
  candyMachineConfigDetector,
  shdwStorageAccountDetector,
  shdwFileAccountDetector,
];

export const detectAccountCache = new Cache<Account, Account>(
  1000,
  (k: Account, v: Account) => k.hasAddress(v),
);

export const detectAccount = async (account: Account, detectors: AccountDetector[] | null = null): Promise<Account> => {
  const cachedAccount = detectAccountCache.get(account);
  if (cachedAccount) return cachedAccount;

  detectors = detectors ?? accountDetectors;
  const detectedAccount = await asyncPipeline(account, detectors);
  detectAccountCache.add(account, detectedAccount);

  return detectedAccount;
};
