import { Coder } from '@project-serum/anchor';
import {
  Account, SHDWFileAccount,
} from '@/models';
import { AccountDetector } from '.';
import { IDL } from '@/utils';

const isSHDWFileAccount = (account: Account): boolean => (account.owner?.isRecognized('shadowDriveProgram') ?? false)
  && (account.accountInfo.data.length <= 125);

export const shdwFileAccountDetector: AccountDetector = async (account, next) => {
  if (!isSHDWFileAccount(account)) return next(account);

  const coder = new Coder(IDL);
  const data = coder.accounts.decode('File', account.accountInfo.data);

  return new SHDWFileAccount(
    account.context,
    account.owner,
    data,
  );
};
