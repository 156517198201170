import {
  Account, AnchorAccount, CandyMachineAccount, CandyMachineConfigAccount,
} from '@/models';
import { AccountDetector } from '.';
import { fetchAccount } from '../fetchAccount';

const isCandyMachine = (account: Account): boolean => account instanceof AnchorAccount
  && (account.owner?.isRecognized('candyMachineProgram') ?? false)
  && account.anchorAccountName === 'CandyMachine';

export const candyMachineDetector: AccountDetector = async (account, next) => {
  if (!(account instanceof AnchorAccount)) return next(account);
  if (!isCandyMachine(account)) return next(account);

  const configAccount = await fetchAccount(account.decodedData.config);

  return new CandyMachineAccount(
    account.context,
    account.owner,
    account.decodedData,
    account.anchorProgram,
    account.anchorAccountName,
    configAccount instanceof CandyMachineConfigAccount ? configAccount : null,
  );
};
